<template>
  <div class="discussion-content">
    <div class="discussion-wrap">
      <div class="__header">
        <h4>Assignment</h4>
      </div>
      <div class="__body">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="pills-scheduled-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-scheduled"
              type="button"
              role="tab"
              aria-controls="pills-scheduled"
              aria-selected="true"
              v-if="isPublished == false"
            >
              Upload
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              :class="isPublished == true ? 'active' : ''"
              id="review-tab-tab"
              data-bs-toggle="pill"
              data-bs-target="#review-tab"
              type="button"
              role="tab"
              aria-controls="review-tab"
              aria-selected="false"
            >
              Review & Publish
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="feedback-pill"
              data-bs-toggle="pill"
              data-bs-target="#feedback-tab"
              type="button"
              role="tab"
              aria-controls="review-tab"
              aria-selected="false"
            >
              Feedback
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade "
            :class="isPublished == false ? 'show active' : ''"
            id="pills-scheduled"
            role="tabpanel"
            aria-labelledby="pills-scheduled-tab"
          >
            <h5>{{ assignment.title }}</h5>
            <p>{{ assignment.description }}</p>

            <ul class="list-unstyled info-list">
              <li>
                <span>Evaluated By:</span> {{ assignment.first_name }}
                {{ assignment.last_name }}
              </li>
              <li><span>Last date:</span>{{ assignment.last_date }}</li>
              <li><span>Subject:</span>{{ assignment.subject }}</li>

              <li v-if="assignment.file != null">
                <span>File :</span
                ><a href="#" @click.prevent="downloadFile(assignment.file)"
                  >File</a
                >
              </li>
            </ul>

            <div class="input-wrapper">
              <form @submit.prevent="saveAssignment">
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    v-model="assignmentDescription"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style="height: 300px"
                  ></textarea>
                  <label for="floatingTextarea2">Comments</label>
                  <div v-if="error && error.assignmentError" class="error-msg">
                    {{ error.assignmentError }}
                  </div>
                </div>
                <button for="" class="btn upload-btn">
                  <!-- <img src="../assets/upload-cloud.svg" alt="" /> -->
                  <input type="file" multiple @change="selectFiles" />
                </button>
                <div v-if="error && error.fileTypeError" class="error-msg">
                  {{ error.fileTypeError }}
                </div>
                <div class="btn-wrap mt-3">
                  <button
                    type="submit"
                    :disabled="isDisabledButton == true"
                    class="cta-primary btn m-auto"
                  >
                    Submit
                  </button>
                </div>
              </form>
              <div>
                <div v-if="progressInfos">
                  <div
                    class="mb-2"
                    v-for="(progressInfo, index) in progressInfos"
                    :key="index"
                  >
                    <span>{{ progressInfo.fileName }}</span>
                    <div class="progress">
                      <div
                        class="progress-bar progress-bar-info"
                        role="progressbar"
                        :aria-valuenow="progressInfo.percentage"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="{ width: progressInfo.percentage + '%' }"
                      >
                        {{ progressInfo.percentage }}%
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="message" role="alert">
                  <ul></ul>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade "
            :class="isPublished == true ? 'show active' : ''"
            id="review-tab"
            role="tabpanel"
            aria-labelledby="review-tab"
          >
            <div v-if="this.assignment.uploads.length != 0">
              <p v-html="assignmentDescription"></p>
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(uploadFile, UploadFileIndex) in uploadedFiles"
                  :key="UploadFileIndex"
                >
                  {{ uploadFile.original_name }}
                  <a
                    href="#"
                    @click="deleteFile(uploadFile.id)"
                    v-if="isPublished == false"
                    >Delete</a
                  >
                </div>
                <button
                  class="btn btn-sm cta-primary"
                  v-if="isPublished == false"
                  @click="publishAssignment"
                >
                  Publish
                </button>
              </div>
            </div>
            <div v-else class="empty">
              Not yet submitted
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="feedback-tab"
            role="tabpanel"
            aria-labelledby="review-tab"
          >
            <div v-if="this.feedback.length != 0">
              <p>{{ feedback.remarks }}</p>
              <p>{{ feedback.mark }} / {{ feedback.total_mark }}</p>
            </div>
            <div v-else class="empty">
              Not reviewed yet
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssignmentService from "@/services/AssignmentService.js";
import errorLog from "@/errorLog";
export default {
  name: "AssignmentPortal",
  components: {},
  data() {
    return {
      selectedFiles: [],
      progressInfos: [],
      assignment: {
        uploads: []
      },
      assignmentDescription: "",
      fileUploadCounter: 0,
      error: {
        fileTypeError: "",
        assignmentError: ""
      },
      message: "",
      isDisabledButton: false,
      uploadId: "",
      uploadedFiles: [],
      isPublished: false,
      feedback: []
    };
  },
  created() {
    this.getAssignment();
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isPupaMember"]);
      return this.$store.getters["privilege/isPupaMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isPupaMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "dashboardTab" });
      }
    }
  },
  methods: {
    selectFiles() {
      this.progressInfos = [];
      this.selectedFiles = event.target.files;
      this.error.fileTypeError = "";
      for (let i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i]["type"] !== "application/pdf") {
          this.error.fileTypeError = "Please upload pdf files only";
        }
      }
    },
    getAssignment() {
      const data = {
        id: this.$route.params.id
      };
      AssignmentService.getAssignment(data)
        .then(result => {
          this.assignment = result.data;
          this.assignment.uploads.filter(value => {
            this.assignmentDescription = value.assignment;
            this.uploadId = value.id;
            this.uploadedFiles = value.files;
            this.isPublished = value.status == "PUBLISHED" ? true : false;
            if (this.isPublished == true) {
              this.getFeetback();
            }
          });
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    saveAssignment() {
      const data = {
        assignment_id: this.assignment.id,
        assignment: this.assignmentDescription,
        id: this.uploadId
      };
      if (this.assignmentDescription == "") {
        this.error.assignmentError = "Please enter description";
        return null;
      } else {
        this.error.assignmentError = "";
      }
      if (this.error.fileTypeError != "") {
        return null;
      } else {
        this.error.fileTypeError = "";
      }
      this.isDisabledButton = true;
      AssignmentService.saveAssignment(data)
        .then(result => {
          if (this.selectedFiles.length == 0) {
            this.isDisabledButton = false;
            this.$toast.success("Assignment Submitted successfully");
            this.getAssignment();
          }
          for (let i = 0; i < this.selectedFiles.length; i++) {
            this.upload(i, this.selectedFiles[i], result.data.result_id);
          }
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    upload(idx, file, resultId) {
      this.progressInfos[idx] = {
        percentage: 0,
        fileName: file.name
      };
      AssignmentService.upload(file, resultId, event => {
        this.progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
        if (this.progressInfos[idx].percentage == 100) {
          this.fileUploadCounter++;
        }
        if (this.selectedFiles.length == this.fileUploadCounter) {
          this.isDisabledButton = false;
          this.$toast.success("Assignment Submitted successfully");
          this.getAssignment();
        }
      })
        .then(response => {
          this.message = idx + response.data.message;
        })
        .catch(() => {
          this.progressInfos[idx].percentage = 0;
          this.message = "Could not upload the file:" + file.name;
        });
    },
    deleteFile(id) {
      const data = {
        id: id
      };

      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You will not be able to recover this file!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, keep it"
        })
        .then(result => {
          if (result.isConfirmed) {
            AssignmentService.deleteFile(data)
              .then(result => {
                if (result.data.status == "success") {
                  this.getAssignment();
                  this.$swal.fire(
                    "Deleted!",
                    "Your  file has been deleted.",
                    "success"
                  );
                }
              })
              .catch(error => {
                errorLog.log(error);
              });
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire("Cancelled", "Your file is safe :)", "error");
          }
        });
    },
    publishAssignment() {
      const data = {
        id: this.uploadId
      };
      AssignmentService.publishAssignment(data)
        .then(result => {
          if (result.data.status == "success") {
            this.$toast.success("Assignment Published successfully");
            this.getAssignment();
          } else {
            this.$toast.error("Invalid request");
          }
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getFeetback() {
      const data = {
        id: this.uploadId
      };
      AssignmentService.getFeedback(data)
        .then(result => {
          if (result.data.status == "success") {
            this.feedback = result.data.feedback;
            console.log(this.feedback);
          }
        })
        .catch(error => {
          errorLog.log(error);
        });
    },

    downloadFile: function(file) {
      AssignmentService.downloadFile(file)
        .then(response => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "assignment_file";
          link.click();
        })
        .catch(error => {
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/assignment-portal.scss";
</style>
