import Http from "@/services/Http";
export default {
  getAssignments(data) {
    return Http().post("assignments/list", data);
  },
  saveAssignment(data) {
    return Http().post("assignments/submit", data);
  },
  getAssignmentReports() {
    return Http().post("report/assignments");
  },
  getAssignment(data) {
    return Http().post("assignment/get", data);
  },
  deleteFile(data) {
    return Http().post("assignment/upload/file/delete", data);
  },
  publishAssignment(data) {
    return Http().post("assignment/publish", data);
  },
  getFeedback(data) {
    return Http().post("assignment/feedback", data);
  },
  downloadFile(data) {
    return Http().get("assignment/file/download?path=" + data, {
      responseType: "arraybuffer"
    });
  },
  upload(file, resultId, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("assignment_result_id", resultId);

    return Http().post("assignments/file/submit", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  }
};
